<template>
  <v-card>
    <v-breadcrumbs :items="sheetInfo" large></v-breadcrumbs>
    <v-tabs v-model="currentTab" center-active>
      <v-tab v-for="(column, index) in columns" :key="index + 'columns'">
        {{ column }}
      </v-tab>
    </v-tabs>
    <v-card-text>
      <v-row class="my-3">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            :prepend-inner-icon="icons.mdiMagnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="selectedTableData.length !== 0" cols="12" md="4">
          <v-btn block color="primary" @click="bulkDeleteSheet">
            <v-icon dark left>
              {{ icons.mdiTrashCan }}
            </v-icon>
            Bulk {{ $t('delete') }} Sheet Row
          </v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn block color="primary" @click="addSheet">
            <v-icon dark left>
              {{ icons.mdiPlusCircle }}
            </v-icon>
            {{ $t('add') }} Sheet Row
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-model="selectedTableData"
        :search="search"
        item-key="id"
        :loading="loading"
        :items="filteredSheetData"
        :headers="dataHeaders"
        :items-per-page="10"
        :sort-desc="[true]"
        multi-sort
        show-select
        class="text-no-wrap text-center"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPageLabel'),
        }"
      >
        <template #[`item.option`]="{ item }">
          <v-btn fab small icon @click="editSheet(item)">
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
          <v-btn fab small icon @click="deleteSheet(item)">
            <v-icon>{{ icons.mdiTrashCan }}</v-icon>
          </v-btn>
        </template>
        <template #[`item.Image`]="{ item }">
          <img style="width: 50px; height: 50px" :src="item.Image" />
        </template>
        <template #[`item.Photo`]="{ item }">
          <img style="width: 50px; height: 50px" :src="item.Photo" />
        </template>
        <!-- <template #[`item.Description`]="{ item }">
          <v-textarea
            :readonly="true"
            rows="1"
            auto-grow
            dense
            name="input-7-4"
            label="Description"
            :value="item.Description"
          ></v-textarea>
        </template> -->
      </v-data-table>

      <!-- Dialog Add Product -->
      <v-dialog v-model="openAddProductSheet" width="80%">
        <v-card>
          <v-snackbar
            v-model="showSnackbarWarning"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 100 !important"
          >
            {{ formValidationMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeSnackbarWarning()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('add') }} Sheet Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <div class="height-dialog">
              <v-form lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Name * </span>
                      <v-icon>
                        {{ icons.mdiShopping }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Name"
                          label="Product Name"
                          :prepend-inner-icon="icons.label"
                          outlined
                          placeholder="Product Name"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Variant Of</span>
                      <v-icon>
                        {{ icons.mdiShopping }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadAdd['Variant Of']"
                          label="Products Variant"
                          :items="productVariants"
                          :prepend-inner-icon="icons.mdiDotsVertical"
                          outlined
                          attach
                          placeholder="Products Variant"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-select>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Base Price * </span>
                      <v-icon>
                        {{ icons.mdiCash }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd['Base Price']"
                          label="Base Price (IDR)"
                          prefix="IDR"
                          outlined
                          type="number"
                          min="0"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Category</span>
                      <v-icon>
                        {{ icons.mdiClipboardList }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Category"
                          label="Category"
                          outlined
                          :prepend-inner-icon="icons.mdiClipboardList"
                          placeholder="Product Category"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Description</span>
                      <v-icon>
                        {{ icons.mdiNewspaperVariant }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Description"
                          label="Description"
                          outlined
                          :prepend-inner-icon="icons.mdiPencilBox"
                          placeholder="Product Description"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Photo * </span>
                      <v-icon>
                        {{ icons.mdiImage }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <uppyUploader
                          ref="uppy-data-product"
                          :key="'onsheetProduct-' + dataIndex"
                          :data-index="'onsheetProduct-' + dataIndex"
                          :status="'add'"
                          :data-uppy="propsUppyUploader"
                          :config="uppySettings"
                          :dashboard-config="uppyDashboardSettings"
                        />
                      </v-card>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" md="6">
                    <!-- <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Category</span>
                      <v-icon>
                        {{ icons.mdiClipboardList }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Category"
                          label="Category"
                          outlined
                          :prepend-inner-icon="icons.mdiClipboardList"
                          placeholder="Product Category"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text> -->

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Add Ons</span>
                      <v-icon>
                        {{ icons.mdiClipboardList }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadAdd['Add Ons']"
                          label="Add Ons"
                          outlined
                          multiple
                          attach
                          :items="productAddOns"
                          placeholder="Product Add Ons"
                          :prepend-inner-icon="icons.mdiClipboardList"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <!-- <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Base Price</span>
                      <v-icon>
                        {{ icons.mdiCash }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd['Base Price']"
                          label="Base Price (IDR)"
                          prefix="IDR"
                          outlined
                          type="number"
                          min="0"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text> -->

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Product Weight{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiWeight }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Weight"
                          type="number"
                          min="0"
                          label="Weight (gram)"
                          outlined
                          placeholder="Product Weight"
                          :prepend-inner-icon="icons.mdiWeightGram"
                          hide-details="auto"
                          suffix="gr"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <!-- "length": 10,
"width": 25,
"height": 20 -->
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Product Width{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Width"
                          type="number"
                          min="0"
                          label="Width (cm)"
                          outlined
                          placeholder="Product Width"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Product Height{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Height"
                          type="number"
                          min="0"
                          label="Height (cm)"
                          outlined
                          placeholder="Product Height"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Product Length{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Length"
                          type="number"
                          min="0"
                          label="Length (cm)"
                          outlined
                          placeholder="Product Length"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Product Type{{
                          currentShop.shopType.text !== 'Hotel' && currentShop.address.enableDeliveryPricingInter
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiApps }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadAdd.Type"
                          label="Product Type"
                          :prepend-inner-icon="icons.mdiApps"
                          outlined
                          attach
                          placeholder="Product Type"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          :items="productTypes"
                        >
                        </v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Out Of Stock * </span>
                      <v-icon>
                        {{ icons.mdiTableCancel }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadAdd['Out Of Stock']"
                          label="Out Of Stock"
                          :prepend-inner-icon="icons.mdiTableCancel"
                          outlined
                          attach
                          placeholder="Product Out Of Stock"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          :items="outOfStockOptions"
                        >
                        </v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-text v-if="loadingAdd" class="d-flex justify-center">
                      <v-progress-circular indeterminate :size="48" color="primary"></v-progress-circular>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-form>
            </div>

            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="closeAddProductDialog">
                <span class="error--text"></span>{{ $t('cancel') }}
              </v-btn>

              <v-btn color="success" @click="saveAddProduct">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-dialog>

      <!-- Dialog Edit Product -->
      <v-dialog v-model="openEditProductSheet" width="80%">
        <v-card>
          <v-snackbar
            v-model="showSnackbarWarning"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 100 !important"
          >
            {{ formValidationMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeSnackbarWarning()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('edit') }} Sheet Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <div class="height-dialog">
              <v-form lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Name * </span>
                      <v-icon>
                        {{ icons.mdiShopping }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Name"
                          label="Product Name"
                          :prepend-inner-icon="icons.mdiLabel"
                          outlined
                          placeholder="Product Name"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Variant Of</span>
                      <v-icon>
                        {{ icons.mdiShopping }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadEdit['Variant Of']"
                          label="Products Variant"
                          :items="productVariants"
                          outlined
                          attach
                          :prepend-inner-icon="icons.mdiDotsVertical"
                          placeholder="Products Variant"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Base Price * </span>
                      <v-icon>
                        {{ icons.mdiCash }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit['Base Price']"
                          label="Base Price (IDR)"
                          prefix="IDR"
                          outlined
                          type="number"
                          min="0"
                          placeholder="Product Base Price"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Category</span>
                      <v-icon>
                        {{ icons.mdiClipboardList }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Category"
                          label="Category"
                          outlined
                          :prepend-inner-icon="icons.mdiClipboardList"
                          placeholder="Product Category"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Description</span>
                      <v-icon>
                        {{ icons.mdiNewspaperVariant }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Description"
                          label="Description"
                          outlined
                          :prepend-inner-icon="icons.mdiPencilBox"
                          placeholder="Product Description"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Photo * </span>
                      <v-icon>
                        {{ icons.mdiImage }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <!-- <v-text-field
                      v-model="payloadEdit.Photo"
                      label="Photo"
                      outlined
                      placeholder="Product Photo"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    ></v-text-field> -->

                        <uppyUploader
                          ref="uppy-data-product"
                          :key="'onsheetProduct-' + dataIndex"
                          :data-index="'onsheetProduct-' + dataIndex"
                          :status="'add'"
                          :data-uppy="propsUppyUploader"
                          :config="uppySettings"
                          :dashboard-config="uppyDashboardSettings"
                        />
                      </v-card>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Category</span>
                      <v-icon>
                        {{ icons.mdiClipboardList }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Category"
                          label="Category"
                          outlined
                          :prepend-inner-icon="icons.mdiClipboardList"
                          placeholder="Product Category"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text> -->

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Add Ons</span>
                      <v-icon>
                        {{ icons.mdiClipboardList }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadEdit['Add Ons']"
                          label="Add Ons"
                          outlined
                          multiple
                          attach
                          :items="productAddOns"
                          :prepend-inner-icon="icons.mdiClipboardList"
                          placeholder="Product Add Ons"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <!-- <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Base Price</span>
                      <v-icon>
                        {{ icons.mdiCash }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit['Base Price']"
                          label="Base Price (IDR)"
                          prefix="IDR"
                          outlined
                          type="number"
                          min="0"
                          placeholder="Product Base Price"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text> -->

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Product Weight{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiTableCancel }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Weight"
                          label="Weight (gram)"
                          suffix="gr"
                          min="0"
                          type="number"
                          :prepend-inner-icon="icons.mdiTableCancel"
                          outlined
                          placeholder="Product Weight"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Product Width{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Width"
                          type="number"
                          min="0"
                          label="Width (cm)"
                          outlined
                          placeholder="Product Width"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Product Height{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Height"
                          type="number"
                          min="0"
                          label="Height (cm)"
                          outlined
                          placeholder="Product Height"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Product Length{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Length"
                          type="number"
                          min="0"
                          label="Length (cm)"
                          outlined
                          placeholder="Product Length"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Product Type{{
                          currentShop.shopType.text !== 'Hotel' && currentShop.address.enableDeliveryPricingInter
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <!-- <v-icon>
                    {{ icons.mdiRadioTower }}
                  </v-icon> -->
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadEdit.Type"
                          label="Type"
                          outlined
                          attach
                          placeholder="Product Type"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          :items="productTypes"
                        >
                        </v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Product Out Of Stock * </span>
                      <!-- <v-icon>
                    {{ icons.mdiRadioTower }}
                  </v-icon> -->
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadEdit['Out Of Stock']"
                          label="Out Of Stock"
                          outlined
                          attach
                          placeholder="Product Out Of Stock"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          :items="outOfStockOptions"
                        >
                        </v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-text v-if="loadingEdit" class="d-flex justify-center">
                      <v-progress-circular indeterminate :size="48" color="primary"></v-progress-circular>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-form>
            </div>

            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="closeEditProductDialog">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>

              <v-btn color="success" @click="saveEditProduct">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-dialog>

      <!-- Dialog Delete Product -->
      <v-dialog v-model="openDeleteProductSheet" width="600px">
        <v-card>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('delete') }} Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <v-card-text>
              <span style="font-weight: bold">Are you sure you want to delete this ?</span>
              <v-progress-circular
                v-if="loadingDelete"
                indeterminate
                class="ms-4"
                :size="32"
                color="primary"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" @click="closeDeleteProductDialog">
              <span style="" class="error--text size-button-action">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="success" @click="saveDeleteProduct">
              <span style="" class="size-button-action">OK</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Add Add On -->
      <v-dialog v-model="openAddAddOnSheet" width="80%">
        <v-card>
          <v-snackbar
            v-model="showSnackbarWarning"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 100 !important"
          >
            {{ formValidationMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeSnackbarWarning()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('add') }} Sheet Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <div class="height-dialog">
              <v-form lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Add On Name * </span>
                      <v-icon>
                        {{ icons.mdiShopping }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Name"
                          label="Product Name"
                          :prepend-inner-icon="icons.mdiLabel"
                          outlined
                          placeholder="Add On Name"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Products * </span>
                      <v-icon>
                        {{ icons.mdiShopping }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadAdd.Products"
                          label="Products"
                          outlined
                          multiple
                          attach
                          :prepend-inner-icon="icons.mdiDotsVertical"
                          :items="addOnProducts"
                          placeholder="Add On Products"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Add On Price * </span>
                      <v-icon>
                        {{ icons.mdiCash }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Price"
                          label="Price"
                          outlined
                          type="number"
                          prefix="IDR"
                          min="0"
                          placeholder="Add On Price"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Image</span>
                      <v-icon>
                        {{ icons.mdiImage }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <uppyUploader
                          ref="uppy-data-addon"
                          :key="'onsheetAddon-' + dataIndex"
                          :data-index="'onsheetAddon-' + dataIndex"
                          :status="'add'"
                          :data-uppy="propsUppyUploader"
                          :config="uppySettings"
                          :dashboard-config="uppyDashboardSettings"
                        />
                      </v-card>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Products</span>
                      <v-icon>
                        {{ icons.mdiShopping }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadAdd.Products"
                          label="Products"
                          outlined
                          multiple
                          attach
                          :prepend-inner-icon="icons.mdiDotsVertical"
                          :items="addOnProducts"
                          placeholder="Add On Products"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Add On Price</span>
                      <v-icon>
                        {{ icons.mdiCash }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Price"
                          label="Price"
                          outlined
                          type="number"
                          prefix="IDR"
                          min="0"
                          placeholder="Add On Price"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text> -->

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Add On Weight{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiWeight }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd['Weight(g)']"
                          label="Weight(g)"
                          outlined
                          min="0"
                          :prepend-inner-icon="icons.mdiWeightGram"
                          type="number"
                          suffix="gr"
                          placeholder="Add On Weight"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Add On Width{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Width"
                          type="number"
                          min="0"
                          label="Width (cm)"
                          outlined
                          placeholder="Add On Width"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Add On Height{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Height"
                          type="number"
                          min="0"
                          label="Height (cm)"
                          outlined
                          placeholder="Add On Height"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Add On Length{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Length"
                          type="number"
                          min="0"
                          label="Length (cm)"
                          outlined
                          placeholder="Add On Length"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Add On Type{{
                          currentShop.shopType.text !== 'Hotel' && currentShop.address.enableDeliveryPricingInter
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiApps }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadAdd.Type"
                          label="Type"
                          outlined
                          :prepend-inner-icon="icons.mdiApps"
                          placeholder="Add On Out Of Stock"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          attach
                          :items="productTypes"
                        >
                        </v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Add On Out Of Stock * </span>
                      <v-icon>
                        {{ icons.mdiTableCancel }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadAdd['Out Of Stock']"
                          label="Out Of Stock"
                          outlined
                          :prepend-inner-icon="icons.mdiTableCancel"
                          placeholder="Add On Out Of Stock"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          attach
                          :items="outOfStockOptions"
                        >
                        </v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-text v-if="loadingAdd" class="d-flex justify-center">
                      <v-progress-circular indeterminate class="mt-4" :size="48" color="primary"></v-progress-circular>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-form>
            </div>

            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="closeAddAddOnDialog">
                <span class="error--text"></span>{{ $t('cancel') }}
              </v-btn>

              <v-btn color="success" @click="saveAddAddOn">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-dialog>

      <!-- Dialog Edit Add On -->
      <v-dialog v-model="openEditAddOnSheet" width="80%">
        <v-card>
          <v-snackbar
            v-model="showSnackbarWarning"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 100 !important"
          >
            {{ formValidationMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeSnackbarWarning()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('edit') }} Sheet Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <div class="height-dialog">
              <v-form lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Add On Name * </span>
                      <v-icon>
                        {{ icons.mdiShopping }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Name"
                          label="Product Name"
                          outlined
                          :prepend-inner-icon="icons.mdiLabel"
                          placeholder="Add On Name"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Products * </span>
                      <v-icon>
                        {{ icons.mdiShopping }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadEdit.Products"
                          label="Products"
                          :prepend-inner-icon="icons.mdiDotsVertical"
                          outlined
                          multiple
                          attach
                          :items="addOnProducts"
                          placeholder="Add On Products"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Add On Price * </span>
                      <v-icon>
                        {{ icons.mdiCash }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Price"
                          label="Price"
                          type="number"
                          min="0"
                          prefix="IDR"
                          outlined
                          placeholder="Add On Price"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Image</span>
                      <v-icon>
                        {{ icons.mdiImage }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <!-- <v-text-field
                      v-model="payloadEdit.Image"
                      label="Add On Image"
                      outlined
                      placeholder="Add On Image"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    ></v-text-field> -->

                        <uppyUploader
                          ref="uppy-data-addon"
                          :key="'onsheetAddon-' + dataIndex"
                          :data-index="'onsheetAddon-' + dataIndex"
                          :status="'add'"
                          :data-uppy="propsUppyUploader"
                          :config="uppySettings"
                          :dashboard-config="uppyDashboardSettings"
                        />
                      </v-card>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Products</span>
                      <v-icon>
                        {{ icons.mdiShopping }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadEdit.Products"
                          label="Products"
                          :prepend-inner-icon="icons.mdiDotsVertical"
                          outlined
                          multiple
                          attach
                          :items="addOnProducts"
                          placeholder="Add On Products"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Add On Price</span>
                      <v-icon>
                        {{ icons.mdiCash }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Price"
                          label="Price"
                          type="number"
                          min="0"
                          prefix="IDR"
                          outlined
                          placeholder="Add On Price"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text> -->

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Add On Weight{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiWeight }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit['Weight(g)']"
                          label="Weight(g)"
                          outlined
                          min="0"
                          type="number"
                          :prepend-inner-icon="icons.mdiWeightGram"
                          suffix="gr"
                          placeholder="Add On Weight"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Add On Width{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Width"
                          type="number"
                          min="0"
                          label="Width (cm)"
                          outlined
                          placeholder="Add On Width"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Add On Height{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Height"
                          type="number"
                          min="0"
                          label="Height (cm)"
                          outlined
                          placeholder="Add On Height"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Add On Length{{
                          currentShop.shopType.text !== 'Hotel' &&
                          (currentShop.address.enableDeliveryPricing || currentShop.address.enableDeliveryPricingInter)
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiRuler }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Length"
                          type="number"
                          min="0"
                          label="Length (cm)"
                          outlined
                          placeholder="Add On Length"
                          :prepend-inner-icon="icons.mdiRuler"
                          hide-details="auto"
                          suffix="cm"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3"
                        >Add On Type{{
                          currentShop.shopType.text !== 'Hotel' && currentShop.address.enableDeliveryPricingInter
                            ? ' * '
                            : ''
                        }}</span
                      >
                      <v-icon>
                        {{ icons.mdiApps }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadEdit.Type"
                          label="Type"
                          outlined
                          :prepend-inner-icon="icons.mdiApps"
                          placeholder="Add On Out Of Stock"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          attach
                          :items="productTypes"
                        >
                        </v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Add On Out Of Stock * </span>
                      <v-icon>
                        {{ icons.mdiTableCancel }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadEdit['Out Of Stock']"
                          label="Out Of Stock"
                          attach
                          outlined
                          :prepend-inner-icon="icons.mdiTableCancel"
                          placeholder="Add On Out Of Stock"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          :items="outOfStockOptions"
                        >
                        </v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-text v-if="loadingEdit" class="d-flex justify-center">
                      <v-progress-circular indeterminate class="mt-4" :size="48" color="primary"></v-progress-circular>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-form>
            </div>

            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="closeEditAddOnDialog">
                <span class="error--text"></span>{{ $t('cancel') }}
              </v-btn>

              <v-btn color="success" @click="saveEditAddOn">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-dialog>

      <!-- Dialog Delete Add On -->
      <v-dialog v-model="openDeleteAddOnSheet" width="600px">
        <v-card>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('delete') }} Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <v-card-text>
              <span style="font-weight: bold">Are you sure you want to delete this ?</span>
              <v-progress-circular
                v-if="loadingDelete"
                indeterminate
                class="ms-4"
                :size="32"
                color="primary"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" @click="closeDeleteAddOnDialog">
              <span style="" class="error--text size-button-action">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="success" @click="saveDeleteAddOn">
              <span style="" class="size-button-action">OK</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Add Coupon -->
      <v-dialog v-model="openAddCouponSheet" width="80%">
        <v-card>
          <v-snackbar
            v-model="showSnackbarWarning"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 100 !important"
          >
            {{ formValidationMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeSnackbarWarning()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('add') }} Sheet Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <div class="height-dialog">
              <v-form lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Code * </span>
                      <v-icon>
                        {{ icons.mdiTag }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Code"
                          label="Code"
                          outlined
                          :prepend-inner-icon="icons.mdiLabel"
                          placeholder="Code"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Discount Percentage * </span>
                      <v-icon>
                        {{ icons.mdiLabelPercent }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd['discountPercentage']"
                          label="Discount Percentage"
                          outlined
                          min="0"
                          type="number"
                          :prepend-inner-icon="icons.mdiLabelPercent"
                          suffix="%"
                          placeholder="Discount Percentage"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Max Discount * </span>
                      <v-icon>
                        {{ icons.mdiLabelPercent }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd['Max Discount']"
                          label="Max Discount"
                          outlined
                          min="0"
                          prefix="IDR"
                          type="number"
                          placeholder="Max Discount"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Minimum Buy * </span>
                      <v-icon>
                        {{ icons.mdiLabelPercent }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd['Minimum Buy']"
                          label="Minimum Buy"
                          outlined
                          placeholder="Minimum Buy"
                          min="0"
                          prefix="IDR"
                          type="number"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Status * </span>
                      <v-icon>
                        {{ icons.mdiTableCancel }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadAdd.Status"
                          label="Status"
                          outlined
                          :prepend-inner-icon="icons.mdiListStatus"
                          placeholder="Status"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          :items="status"
                          attach
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Start Date * </span>
                      <!-- <v-icon>
                    {{ icons.mdiRadioTower }}
                  </v-icon> -->
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-menu
                          v-model="openDateStartCoupon"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :label="$t('broadcasts.date')"
                              :prepend-inner-icon="icons.mdiCalendarMonth"
                              outlined
                              dense
                              :value="payloadAdd['Start Date']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="payloadAdd['Start Date']" no-title scrollable> </v-date-picker>
                        </v-menu>
                        <!-- <v-text-field
                      v-model="payloadAdd['Start Date']"
                      label="Start Date"
                      outlined
                      placeholder="Start Date"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    >
                    </v-text-field> -->
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Expired Date * </span>
                      <!-- <v-icon>
                    {{ icons.mdiRadioTower }}
                  </v-icon> -->
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-menu
                          v-model="openDateEndCoupon"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :label="$t('broadcasts.date')"
                              :prepend-inner-icon="icons.mdiCalendarMonth"
                              outlined
                              dense
                              :value="payloadAdd['Expired Date']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="payloadAdd['Expired Date']" no-title scrollable> </v-date-picker>
                        </v-menu>
                        <!-- <v-text-field
                      v-model="payloadAdd['Expired Date']"
                      label="Expired Date"
                      outlined
                      placeholder="Expired Date"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    >
                    </v-text-field> -->
                      </v-card>
                    </v-card-text>

                    <v-card-text v-if="loadingAdd" class="d-flex justify-center">
                      <v-progress-circular indeterminate :size="48" color="primary"></v-progress-circular>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-form>
            </div>

            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="closeAddCouponDialog">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>

              <v-btn color="success" @click="saveAddCoupon">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-dialog>

      <!-- Dialog Edit Coupon -->
      <v-dialog v-model="openEditCouponSheet" width="80%">
        <v-snackbar
          v-model="showSnackbarWarning"
          color="error"
          :timeout="2000"
          :top="true"
          style="z-index: 100 !important"
        >
          {{ formValidationMessage }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="closeSnackbarWarning()">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-card>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('edit') }} Sheet Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <div class="height-dialog">
              <v-form lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Code * </span>
                      <v-icon>
                        {{ icons.mdiTag }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Code"
                          label="Code"
                          outlined
                          :prepend-inner-icon="icons.mdiLabel"
                          placeholder="Code"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Discount Percentage * </span>
                      <v-icon>
                        {{ icons.mdiLabelPercent }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit['discountPercentage']"
                          label="Discount Percentage"
                          min="0"
                          type="number"
                          outlined
                          :prepend-inner-icon="icons.mdiLabelPercent"
                          suffix="%"
                          placeholder="Discount Percentage"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Max Discount * </span>
                      <v-icon>
                        {{ icons.mdiLabelPercent }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit['Max Discount']"
                          min="0"
                          type="number"
                          label="Max Discount"
                          prefix="IDR"
                          outlined
                          placeholder="Max Discount"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Minimum Buy * </span>
                      <v-icon>
                        {{ icons.mdiLabelPercent }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit['Minimum Buy']"
                          label="Minimum Buy"
                          min="0"
                          outlined
                          type="number"
                          prefix="IDR"
                          placeholder="Minimum Buy"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Status * </span>
                      <v-icon>
                        {{ icons.mdiTableCancel }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadEdit.Status"
                          label="Status"
                          outlined
                          attach
                          placeholder="Status"
                          :prepend-inner-icon="icons.mdiListStatus"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          :items="status"
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Start Date * </span>
                      <!-- <v-icon>
                    {{ icons.mdiRadioTower }}
                  </v-icon> -->
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <!-- <v-text-field
                      v-model="payloadEdit['Start Date']"
                      label="Start Date"
                      outlined
                      placeholder="Start Date"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    >
                    </v-text-field> -->

                        <v-menu
                          v-model="openDateStartCoupon"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :label="$t('broadcasts.date')"
                              :prepend-inner-icon="icons.mdiCalendarMonth"
                              outlined
                              dense
                              :value="payloadEdit['Start Date']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="payloadEdit['Start Date']" no-title scrollable> </v-date-picker>
                        </v-menu>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Expired Date * </span>
                      <!-- <v-icon>
                    {{ icons.mdiRadioTower }}
                  </v-icon> -->
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <!-- <v-text-field
                      v-model="payloadEdit['Expired Date']"
                      label="Expired Date"
                      outlined
                      placeholder="Expired Date"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    >
                    </v-text-field> -->

                        <v-menu
                          v-model="openDateEndCoupon"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :label="$t('broadcasts.date')"
                              :prepend-inner-icon="icons.mdiCalendarMonth"
                              outlined
                              dense
                              :value="payloadEdit['Expired Date']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="payloadEdit['Expired Date']" no-title scrollable> </v-date-picker>
                        </v-menu>
                      </v-card>
                    </v-card-text>

                    <v-card-text v-if="loadingEdit" class="d-flex justify-center">
                      <v-progress-circular indeterminate :size="48" color="primary"></v-progress-circular>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-form>
            </div>

            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="closeEditCouponDialog">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>

              <v-btn color="success" @click="saveEditCoupon">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-dialog>

      <!-- Dialog Delete Coupon -->
      <v-dialog v-model="openDeleteCouponSheet" width="600px">
        <v-card>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('delete') }} Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <v-card-text>
              <!-- <pre>{{deleteShopPayload}}</pre> -->
              <span style="font-weight: bold">Are you sure you want to delete this ?</span>
              <v-progress-circular
                v-if="loadingDelete"
                indeterminate
                class="ms-4"
                :size="32"
                color="primary"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" @click="closeDeleteCouponDialog">
              <span style="" class="error--text size-button-action">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="success" @click="saveDeleteCoupon">
              <span style="" class="size-button-action">OK</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Add Banner -->
      <v-dialog v-model="openAddBannerSheet" width="80%">
        <v-card>
          <v-snackbar
            v-model="showSnackbarWarning"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 100 !important"
          >
            {{ formValidationMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeSnackbarWarning()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('add') }} Sheet Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <div class="height-dialog">
              <v-form lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Name * </span>
                      <v-icon>
                        {{ icons.mdiLabel }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadAdd.Name"
                          label="Name"
                          outlined
                          :prepend-inner-icon="icons.mdiPencilBox"
                          placeholder="Name"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Image * </span>
                      <v-icon>
                        {{ icons.mdiImage }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <uppyUploader
                          ref="uppy-data-banner"
                          :key="'onsheetBanner-' + dataIndex"
                          :data-index="'onsheetBanner-' + dataIndex"
                          :status="'add'"
                          :data-uppy="propsUppyUploader"
                          :config="uppySettings"
                          :dashboard-config="uppyDashboardSettings"
                        />
                      </v-card>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Status * </span>
                      <v-icon>
                        {{ icons.mdiTableCancel }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadAdd.Status"
                          label="Status"
                          outlined
                          attach
                          placeholder="Status"
                          :prepend-inner-icon="icons.mdiListStatus"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          :items="status"
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Start Date * </span>
                      <!-- <v-icon>
                    {{ icons.mdiRadioTower }}
                  </v-icon> -->
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <!-- <v-text-field
                      v-model="payloadAdd['Start Date']"
                      label="Start Date"
                      outlined
                      placeholder="Start Date"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    >
                    </v-text-field> -->

                        <v-menu
                          v-model="openDateStartBanner"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :label="$t('broadcasts.date')"
                              :prepend-inner-icon="icons.mdiCalendarMonth"
                              outlined
                              dense
                              :value="payloadAdd['Start Date']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="payloadAdd['Start Date']" no-title scrollable> </v-date-picker>
                        </v-menu>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Expired Date * </span>
                      <!-- <v-icon>
                    {{ icons.mdiRadioTower }}
                  </v-icon> -->
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <!-- <v-text-field
                      v-model="payloadAdd['Expired Date']"
                      label="Expired Date"
                      outlined
                      placeholder="Expired Date"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    >
                    </v-text-field> -->

                        <v-menu
                          v-model="openDateEndBanner"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :label="$t('broadcasts.date')"
                              :prepend-inner-icon="icons.mdiCalendarMonth"
                              outlined
                              dense
                              :value="payloadAdd['Expired Date']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="payloadAdd['Expired Date']" no-title scrollable> </v-date-picker>
                        </v-menu>
                      </v-card>
                    </v-card-text>

                    <v-card-text v-if="loadingAdd" class="d-flex justify-center">
                      <v-progress-circular indeterminate :size="48" color="primary"></v-progress-circular>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-form>
            </div>

            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="closeAddBannerDialog">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>

              <v-btn color="success" @click="saveAddBanner">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-dialog>

      <!-- Dialog Edit Banner -->
      <v-dialog v-model="openEditBannerSheet" width="80%">
        <v-card>
          <v-snackbar
            v-model="showSnackbarWarning"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 100 !important"
          >
            {{ formValidationMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeSnackbarWarning()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('edit') }} Sheet Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <div class="height-dialog">
              <v-form lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Name * </span>
                      <v-icon>
                        {{ icons.mdiLabel }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-text-field
                          v-model="payloadEdit.Name"
                          label="Name"
                          outlined
                          :prepend-inner-icon="icons.mdiPencilBox"
                          placeholder="Name"
                          hide-details="auto"
                          class="mb-3"
                          dense
                        ></v-text-field>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Image * </span>
                      <v-icon>
                        {{ icons.mdiImage }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <!-- <v-text-field
                      v-model="payloadEdit['Image']"
                      label="Image"
                      outlined
                      placeholder="Image"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    ></v-text-field> -->

                        <uppyUploader
                          ref="uppy-data-banner"
                          :key="'onsheetBanner-' + dataIndex"
                          :data-index="'onsheetBanner-' + dataIndex"
                          :status="'add'"
                          :data-uppy="propsUppyUploader"
                          :config="uppySettings"
                          :dashboard-config="uppyDashboardSettings"
                        />
                      </v-card>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Status * </span>
                      <v-icon>
                        {{ icons.mdiTableCancel }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <v-select
                          v-model="payloadEdit.Status"
                          label="Status"
                          outlined
                          attach
                          :prepend-inner-icon="icons.mdiListStatus"
                          placeholder="Status"
                          hide-details="auto"
                          class="mb-3"
                          dense
                          :items="status"
                        ></v-select>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Start Date * </span>
                      <!-- <v-icon>
                    {{ icons.mdiRadioTower }}
                  </v-icon> -->
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <!-- <v-text-field
                      v-model="payloadEdit['Start Date']"
                      label="Start Date"
                      outlined
                      placeholder="Start Date"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    >
                    </v-text-field> -->

                        <v-menu
                          v-model="openDateStartBanner"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :label="$t('broadcasts.date')"
                              :prepend-inner-icon="icons.mdiCalendarMonth"
                              outlined
                              dense
                              :value="payloadEdit['Start Date']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="payloadEdit['Start Date']" no-title scrollable> </v-date-picker>
                        </v-menu>
                      </v-card>
                    </v-card-text>

                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Expired Date * </span>
                      <!-- <v-icon>
                    {{ icons.mdiRadioTower }}
                  </v-icon> -->
                    </v-card-title>
                    <v-card-text>
                      <v-card>
                        <!-- <v-text-field
                      v-model="payloadEdit['Expired Date']"
                      label="Expired Date"
                      outlined
                      placeholder="Expired Date"
                      hide-details="auto"
                      class="mb-3"
                      dense
                    >
                    </v-text-field> -->

                        <v-menu
                          v-model="openDateEndBanner"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :label="$t('broadcasts.date')"
                              :prepend-inner-icon="icons.mdiCalendarMonth"
                              outlined
                              dense
                              :value="payloadEdit['Expired Date']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="payloadEdit['Expired Date']" no-title scrollable> </v-date-picker>
                        </v-menu>
                      </v-card>
                    </v-card-text>

                    <v-card-text v-if="loadingEdit" class="d-flex justify-center">
                      <v-progress-circular indeterminate :size="48" color="primary"></v-progress-circular>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-form>
            </div>

            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="closeEditBannerDialog">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>

              <v-btn color="success" @click="saveEditBanner">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-dialog>

      <!-- Dialog Delete Banner -->
      <v-dialog v-model="openDeleteBannerSheet" width="600px">
        <v-card>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('delete') }} Row</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <v-card-text>
              <span style="font-weight: bold">Are you sure you want to delete this ?</span>
              <v-progress-circular
                v-if="loadingDelete"
                indeterminate
                class="ms-4"
                :size="32"
                color="primary"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" @click="closeDeleteBannerDialog">
              <span style="" class="error--text size-button-action">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="success" @click="saveDeleteBanner">
              <span style="" class="size-button-action">OK</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiPencil,
  mdiTrashCan,
  mdiLabel,
  mdiShopping,
  mdiPencilBox,
  mdiWeight,
  mdiTableCancel,
  mdiWeightGram,
  mdiCash,
  mdiClipboardList,
  mdiImage,
  mdiNewspaperVariant,
  mdiDotsVertical,
  mdiMagnify,
  mdiPlusCircle,
  mdiCalendarMonth,
  mdiTag,
  mdiLabelPercent,
  mdiListStatus,
  mdiApps,
  mdiRuler,
} from '@mdi/js'
import uppyUploader from '@/components/UppyUploader.vue'

export default {
  name: 'SheetEditor',
  components: {
    uppyUploader,
  },
  data() {
    return {
      columns: ['Products', 'Add Ons', 'Coupons', 'Banners'],
      currentTab: 0,
      selectedTableData: [],
      icons: {
        mdiApps,
        mdiPencil,
        mdiTrashCan,
        mdiLabel,
        mdiShopping,
        mdiPencilBox,
        mdiWeight,
        mdiTableCancel,
        mdiWeightGram,
        mdiCash,
        mdiClipboardList,
        mdiImage,
        mdiNewspaperVariant,
        mdiDotsVertical,
        mdiMagnify,
        mdiPlusCircle,
        mdiCalendarMonth,
        mdiTag,
        mdiLabelPercent,
        mdiRuler,
        mdiListStatus,
      },
      loading: false,
      sheetInfo: [
        {
          text: 'E-Shop',
          disabled: false,
          link: true,
          exact: true,
          to: {
            name: 'e-ordering',
          },
        },
        {
          text: 'GSheet',
          disabled: false,
          href: `https://docs.google.com/spreadsheets/d/${this.$route.params.driveId}`,
        },
      ],

      // Uppy
      dataIndex: Math.round(Math.random() * 100000),
      propsUppyUploader: null,

      // uppy setting
      uppySettings: {
        restrictions: {
          maxFileSize: 100 * 1024 * 1024,
          allowedFileTypes: ['.jpg', '.jpeg', '.png'],
          maxNumberOfFiles: 1,
        },
      },
      uppyDashboardSettings: {
        note: ' Image Only',
      },

      //! Product
      openAddProductSheet: false,
      openEditProductSheet: false,
      openDeleteProductSheet: false,
      productVariants: [],
      productAddOns: [],

      //! Add On
      openAddAddOnSheet: false,
      openEditAddOnSheet: false,
      openDeleteAddOnSheet: false,
      addOnProducts: [],

      //! Coupon
      openAddCouponSheet: false,
      openEditCouponSheet: false,
      openDeleteCouponSheet: false,

      //! Banner
      openAddBannerSheet: false,
      openEditBannerSheet: false,
      openDeleteBannerSheet: false,

      search: '',
      payloadAdd: {},
      payloadEdit: {},
      payloadDelete: {},
      outOfStockOptions: ['YES', 'NO'],
      productTypes: ['Peckage', 'Food', 'Herbal', 'Cosmetic'],
      status: ['Active', 'Inactive'],
      openDateStartBanner: false,
      openDateStartCoupon: false,
      openDateEndBanner: false,
      openDateEndCoupon: false,
      loadingAdd: false,
      loadingEdit: false,
      loadingDelete: false,

      // validate
      showSnackbarWarning: false,
      formValidationMessage: '',
    }
  },
  computed: {
    currentShop() {
      return this.$store.getters['eOrdering/getCurrentShop']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    sheetData() {
      return this.$store.getters['eOrdering/getSheetData']
    },
    filteredSheetData() {
      if (this.currentTab === 0) {
        let finalProductData = this.sheetData?.value?.products
        if (typeof JSON.stringify(finalProductData) !== 'undefined') {
          finalProductData = JSON.parse(JSON.stringify(finalProductData))
          this.productVariants = []
          this.productAddOns = []

          finalProductData.forEach(el => {
            if (el['Variant Of'] === null) {
              this.productVariants.push(el.Name)
            }
          })
          this.productAddOns = JSON.parse(JSON.stringify(this.sheetData?.value?.addOns.map(el => el.Name)))

          finalProductData.forEach(el => {
            if (el['Add Ons']) {
              el['Add Ons'] = el['Add Ons'].split(', ')
              el.addons = el['Add Ons'].join(', ')
            } else {
              el.addons = ''
            }
          })

          if (this.loading) {
            return []
          }

          return finalProductData
        }
      }
      if (this.currentTab === 1) {
        const finalAddOnData = JSON.parse(JSON.stringify(this.sheetData?.value?.addOns))
        this.addOnProducts = []

        this.addOnProducts = JSON.parse(JSON.stringify(this.sheetData?.value?.products.map(el => el.Name)))

        finalAddOnData.forEach(el => {
          if (el.Products) {
            el.Products = el.Products.split(', ')
            el.products = el.Products.join(', ')
          } else {
            el.products = ''
          }
        })

        if (this.loading) {
          return []
        }

        return finalAddOnData
      }
      if (this.currentTab === 2) {
        const finalCoupon = JSON.parse(JSON.stringify(this.sheetData?.value?.coupons))

        finalCoupon.forEach(el => {
          if (el['Discount Percentage']) {
            el.discountPercentage = el['Discount Percentage'].replace('%', '')
          } else {
            el.discountPercentage = ''
          }
        })

        if (this.loading) {
          return []
        }

        return finalCoupon
      }

      if (this.loading) {
        return []
      }

      return this.sheetData?.value?.banners
    },
    dataHeaders() {
      //       "length": 10,
      // "width": 25,
      // "height": 20,
      if (this.currentTab === 0) {
        return [
          { text: 'Action', value: 'option', align: 'center' },
          { text: 'Name', value: 'Name', align: 'center' },
          { text: 'Variant Of', value: 'Variant Of', align: 'center' },
          { text: 'Description', value: 'Description', align: 'center' },
          { text: 'Photo', value: 'Photo', align: 'center' },
          { text: 'Category', value: 'Category', align: 'center' },
          { text: 'Add Ons', value: 'addons', align: 'center' },
          { text: 'Base Price', value: 'Base Price', align: 'center' },
          { text: 'Weight', value: 'Weight', align: 'center' },
          { text: 'Out Of Stock', value: 'Out Of Stock', align: 'center' },
          { text: 'Width', value: 'Width', align: 'center' },
          { text: 'Height', value: 'Height', align: 'center' },
          { text: 'Length', value: 'Length', align: 'center' },
          { text: 'Type', value: 'Type', align: 'center' },
        ]
      }
      if (this.currentTab === 1) {
        return [
          { text: 'Action', value: 'option', align: 'center' },
          { text: 'Name', value: 'Name', align: 'center' },
          { text: 'Image', value: 'Image', align: 'center' },
          { text: 'Products', value: 'products', align: 'center' },
          { text: 'Price', value: 'Price', align: 'center' },
          { text: 'Weight(g)', value: 'Weight(g)', align: 'center' },
          { text: 'Out Of Stock', value: 'Out Of Stock', align: 'center' },
          { text: 'Width', value: 'Width', align: 'center' },
          { text: 'Height', value: 'Height', align: 'center' },
          { text: 'Length', value: 'Length', align: 'center' },
          { text: 'Type', value: 'Type', align: 'center' },
        ]
      }
      if (this.currentTab === 2) {
        return [
          { text: 'Action', value: 'option', align: 'center' },
          { text: 'Code', value: 'Code', align: 'center' },
          { text: 'Discount Percentage', value: 'Discount Percentage', align: 'center' },
          { text: 'Max Discount', value: 'Max Discount', align: 'center' },
          { text: 'Minimum Buy', value: 'Minimum Buy', align: 'center' },
          { text: 'Status', value: 'Status', align: 'center' },
          { text: 'Start Date', value: 'Start Date', align: 'center' },
          { text: 'Expired Date', value: 'Expired Date', align: 'center' },
        ]
      }

      return [
        { text: 'Action', value: 'option', align: 'center' },
        { text: 'Name', value: 'Name', align: 'center' },
        { text: 'Image', value: 'Image', align: 'center' },
        { text: 'Status', value: 'Status', align: 'center' },
        { text: 'Start Date', value: 'Start Date', align: 'center' },
        { text: 'Expired Date', value: 'Expired Date', align: 'center' },
      ]
    },
  },
  async mounted() {
    this.sheetInfo.push({
      text: this.$route.params.name,
      disabled: true,
      href: '/',
    })

    const payload = {
      id: this.$route.params.id,
      driveId: this.$route.params.driveId,
      sub_id: this.user.sub_id,
    }

    this.loading = true
    await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
    await this.$store.dispatch('eOrdering/getSheetData', {
      sub_id: this.user.sub_id,
      excelId: this.$route.params.id,
    })

    // //console.log(this.currentShop)
    this.loading = false
  },
  methods: {
    closeSnackbarWarning() {
      this.showSnackbarWarning = false
    },
    async bulkDeleteSheet() {
      if (this.currentTab === 0) {
        this.openDeleteProductSheet = true
      } else if (this.currentTab === 1) {
        this.openDeleteAddOnSheet = true
      } else if (this.currentTab === 2) {
        this.openDeleteCouponSheet = true
      } else {
        this.openDeleteBannerSheet = true
      }
    },
    addSheet() {
      if (this.currentTab === 0) {
        this.openAddProductSheet = true
      } else if (this.currentTab === 1) {
        this.openAddAddOnSheet = true
      } else if (this.currentTab === 2) {
        this.openAddCouponSheet = true
      } else {
        this.openAddBannerSheet = true
      }
    },
    editSheet(item) {
      if (this.currentTab === 0) {
        this.openEditProductSheet = true
      } else if (this.currentTab === 1) {
        this.openEditAddOnSheet = true
      } else if (this.currentTab === 2) {
        this.openEditCouponSheet = true
      } else {
        this.openEditBannerSheet = true
      }
      this.payloadEdit = JSON.parse(JSON.stringify(item))
    },
    deleteSheet(item) {
      if (this.currentTab === 0) {
        this.openDeleteProductSheet = true
      } else if (this.currentTab === 1) {
        this.openDeleteAddOnSheet = true
      } else if (this.currentTab === 2) {
        this.openDeleteCouponSheet = true
      } else {
        this.openDeleteBannerSheet = true
      }
      this.payloadDelete = JSON.parse(JSON.stringify(item))
    },

    //! Edit Product
    async saveEditProduct() {
      this.payloadEdit.Photo = this.$refs['uppy-data-product']?.uppy?.getFiles()[0]
        ? this.$refs['uppy-data-product']?.uppy?.getFiles()[0].uploadURL
        : this.payloadEdit.Photo
      if (this.validateProduct(this.payloadEdit)) {
        const payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Products',
          driveId: this.$route.params.driveId,
          excelData: {},
        }

        const currentProduct = this.filteredSheetData.find(data => data.id === this.payloadEdit.id)
        const filteredProduct = this.filteredSheetData.filter(data => data['Variant Of'] === currentProduct.Name)

        filteredProduct.forEach(data => {
          payloadSheet.excelData[data.rowNumber] = JSON.parse(JSON.stringify(data))
          payloadSheet.excelData[data.rowNumber]['Variant Of'] = this.payloadEdit.Name

          payloadSheet.excelData[data.rowNumber]['Add Ons'] = payloadSheet.excelData[data.rowNumber]['Add Ons']
            ? payloadSheet.excelData[data.rowNumber]['Add Ons'].join(', ')
            : null

          delete payloadSheet.excelData[data.rowNumber].id
          delete payloadSheet.excelData[data.rowNumber].rowNumber
          delete payloadSheet.excelData[data.rowNumber].addons
        })

        payloadSheet.excelData[this.payloadEdit.rowNumber] = JSON.parse(JSON.stringify(this.payloadEdit))

        payloadSheet.excelData[this.payloadEdit.rowNumber]['Add Ons'] = payloadSheet.excelData[
          this.payloadEdit.rowNumber
        ]['Add Ons']
          ? payloadSheet.excelData[this.payloadEdit.rowNumber]['Add Ons'].join(', ')
          : null

        delete payloadSheet.excelData[this.payloadEdit.rowNumber].id
        delete payloadSheet.excelData[this.payloadEdit.rowNumber].rowNumber
        delete payloadSheet.excelData[this.payloadEdit.rowNumber].addons

        this.loadingEdit = true

        const res = await this.$store.dispatch('eOrdering/editSheet', payloadSheet)

        if (res.status) {
          this.loadingEdit = false
          this.openEditProductSheet = false
          this.payloadEdit = {}
          const payload = {
            id: this.$route.params.id,
            driveId: this.$route.params.driveId,
            sub_id: this.user.sub_id,
          }
          this.loading = true
          await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
          await this.$store.dispatch('eOrdering/getSheetData', {
            sub_id: this.user.sub_id,
            excelId: this.$route.params.id,
          })
          this.loading = false
        }
        this.loadingEdit = false

        this.openEditProductSheet = false
      }
    },
    closeEditProductDialog() {
      this.openEditProductSheet = false
    },

    // Validate Product
    //   //console.log(this.currentShop)
    validateProduct(data) {
      let flag = true
      if (!data.Photo) {
        this.formValidationMessage = 'Product image is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data.Name) {
        this.formValidationMessage = 'Product Name is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data['Base Price']) {
        this.formValidationMessage = 'Product Base Price is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricing &&
        !data.Weight
      ) {
        this.formValidationMessage = 'Product Weight is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricing &&
        !data.Width
      ) {
        this.formValidationMessage = 'Product Width is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricing &&
        !data.Length
      ) {
        this.formValidationMessage = 'Product Length is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricing &&
        !data.Height
      ) {
        this.formValidationMessage = 'Product Height is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data['Out Of Stock']) {
        this.formValidationMessage = 'Product Out Of Stock is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricingInter &&
        !data.Weight
      ) {
        this.formValidationMessage = 'Product Weight is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricingInter &&
        !data.Width
      ) {
        this.formValidationMessage = 'Product Width is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricingInter &&
        !data.Length
      ) {
        this.formValidationMessage = 'Product Length is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricingInter &&
        !data.Height
      ) {
        this.formValidationMessage = 'Product Height is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricingInter &&
        !data.Type
      ) {
        this.formValidationMessage = 'Product Type is Required'
        this.showSnackbarWarning = true
        flag = false
      }

      return flag
    },

    //! Add Product
    async saveAddProduct() {
      this.payloadAdd.Photo = this.$refs['uppy-data-product']?.uppy?.getFiles()[0]
        ? this.$refs['uppy-data-product']?.uppy?.getFiles()[0].uploadURL
        : null

      // //console.log(this.$refs['uppy-data']?.uppy?.getFiles()[0], 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
      if (this.validateProduct(this.payloadAdd)) {
        const payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Products',
          driveId: this.$route.params.driveId,
          excelData: [JSON.parse(JSON.stringify(this.payloadAdd))],
        }

        if (this.payloadAdd['Add Ons']) {
          payloadSheet.excelData[0]['Add Ons'] = payloadSheet.excelData[0]['Add Ons'].join(', ')
        }

        this.loadingAdd = true

        const res = await this.$store.dispatch('eOrdering/addSheet', payloadSheet)

        if (res.status) {
          this.propsUppyUploader = null
          this.loadingAdd = false
          this.openAddProductSheet = false
          this.payloadAdd = {}
          const payload = {
            id: this.$route.params.id,
            driveId: this.$route.params.driveId,
            sub_id: this.user.sub_id,
          }
          this.loading = true
          await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
          await this.$store.dispatch('eOrdering/getSheetData', {
            sub_id: this.user.sub_id,
            excelId: this.$route.params.id,
          })
          this.loading = false
        }
        this.loadingAdd = false

        this.closeAddProductDialog()
      }
    },
    closeAddProductDialog() {
      this.openAddProductSheet = false
      this.propsUppyUploader = null
      this.dataIndex = Math.round(Math.random() * 100000)
    },

    //! Delete Product
    async saveDeleteProduct() {
      let payloadSheet = {
        sub_id: this.user.sub_id,
        sheetName: 'Products',
        driveId: this.$route.params.driveId,
        excelData: [this.payloadDelete.rowNumber],
      }

      if (this.selectedTableData.length !== 0) {
        payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Products',
          driveId: this.$route.params.driveId,
          excelData: this.selectedTableData.map(el => el.rowNumber),
        }
      }

      this.loadingDelete = true

      const res = await this.$store.dispatch('eOrdering/deleteSheet', payloadSheet)

      if (res.status) {
        this.selectedTableData = []
        this.loadingDelete = false
        this.openDeleteProductSheet = false
        const payload = {
          id: this.$route.params.id,
          driveId: this.$route.params.driveId,
          sub_id: this.user.sub_id,
        }
        this.loading = true
        await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
        await this.$store.dispatch('eOrdering/getSheetData', {
          sub_id: this.user.sub_id,
          excelId: this.$route.params.id,
        })
        this.loading = false
      }
      this.selectedTableData = []
      this.loadingDelete = false
      this.openDeleteProductSheet = false
    },
    closeDeleteProductDialog() {
      this.openDeleteProductSheet = false
    },

    validateAddon(data) {
      // //console.log(data, `INI DATA`)
      let flag = true
      if (!data.Image) {
        this.formValidationMessage = 'Addon image is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data.Name) {
        this.formValidationMessage = 'Addon Name is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data['Base Price']) {
        this.formValidationMessage = 'Addon Base Price is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricing &&
        !data.Weight
      ) {
        this.formValidationMessage = 'Addon Weight is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricing &&
        !data.Width
      ) {
        this.formValidationMessage = 'Addon Width is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricing &&
        !data.Length
      ) {
        this.formValidationMessage = 'Addon Length is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricing &&
        !data.Height
      ) {
        this.formValidationMessage = 'Addon Height is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data['Out Of Stock']) {
        this.formValidationMessage = 'Addon Out Of Stock is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricingInter &&
        !data.Weight
      ) {
        this.formValidationMessage = 'Addon Weight is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricingInter &&
        !data.Width
      ) {
        this.formValidationMessage = 'Addon Width is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricingInter &&
        !data.Length
      ) {
        this.formValidationMessage = 'Addon Length is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricingInter &&
        !data.Height
      ) {
        this.formValidationMessage = 'Addon Height is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (
        this.currentShop.shopType.text !== 'Hotel' &&
        this.currentShop.address.enableDeliveryPricingInter &&
        !data.Type
      ) {
        this.formValidationMessage = 'Addon Type is Required'
        this.showSnackbarWarning = true
        flag = false
      }

      return flag
    },

    //! Edit Add On
    async saveEditAddOn() {
      this.payloadEdit.Image = this.$refs['uppy-data-addon']?.uppy?.getFiles()[0]
        ? this.$refs['uppy-data-addon']?.uppy?.getFiles()[0].uploadURL
        : this.payloadEdit.Image
      if (this.validateAddon(this.payloadEdit)) {
        const payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Add Ons',
          driveId: this.$route.params.driveId,
          excelData: {},
        }

        payloadSheet.excelData[this.payloadEdit.rowNumber] = JSON.parse(JSON.stringify(this.payloadEdit))
        payloadSheet.excelData[this.payloadEdit.rowNumber].Products = payloadSheet.excelData[this.payloadEdit.rowNumber]
          .Products
          ? payloadSheet.excelData[this.payloadEdit.rowNumber].Products.join(', ')
          : null
        delete payloadSheet.excelData[this.payloadEdit.rowNumber].id
        delete payloadSheet.excelData[this.payloadEdit.rowNumber].rowNumber
        delete payloadSheet.excelData[this.payloadEdit.rowNumber].products

        this.loadingEdit = true

        const res = await this.$store.dispatch('eOrdering/editSheet', payloadSheet)
        if (res.status) {
          this.loadingEdit = false
          this.openEditAddOnSheet = false
          this.payloadEdit = {}
          const payload = {
            id: this.$route.params.id,
            driveId: this.$route.params.driveId,
            sub_id: this.user.sub_id,
          }
          this.loading = true
          await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
          await this.$store.dispatch('eOrdering/getSheetData', {
            sub_id: this.user.sub_id,
            excelId: this.$route.params.id,
          })
          this.loading = false
        }
        this.loadingEdit = false

        this.openEditAddOnSheet = false
      }
    },
    closeEditAddOnDialog() {
      this.openEditAddOnSheet = false
    },

    //! Add AddOn
    async saveAddAddOn() {
      this.payloadAdd.Image = this.$refs['uppy-data-addon']?.uppy?.getFiles()[0]
        ? this.$refs['uppy-data-addon']?.uppy?.getFiles()[0].uploadURL
        : null
      if (this.validateAddon(this.payloadAdd)) {
        const payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Add Ons',
          driveId: this.$route.params.driveId,
          excelData: [JSON.parse(JSON.stringify(this.payloadAdd))],
        }

        if (this.payloadAdd['Add Ons']) {
          payloadSheet.excelData[0]['Add Ons'] = payloadSheet.excelData[0]['Add Ons'].join(', ')
        }

        this.loadingAdd = true

        const res = await this.$store.dispatch('eOrdering/addSheet', payloadSheet)

        if (res.status) {
          this.loadingAdd = false
          this.openAddAddOnSheet = false
          this.payloadAdd = {}
          const payload = {
            id: this.$route.params.id,
            driveId: this.$route.params.driveId,
            sub_id: this.user.sub_id,
          }
          this.loading = true
          await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
          await this.$store.dispatch('eOrdering/getSheetData', {
            sub_id: this.user.sub_id,
            excelId: this.$route.params.id,
          })
          this.loading = false
        }
        this.loadingAdd = false
        this.closeAddAddOnDialog()
      }
    },
    closeAddAddOnDialog() {
      this.openAddAddOnSheet = false
      this.propsUppyUploader = null
      this.dataIndex = Math.round(Math.random() * 100000)
    },

    //! Delete AddOn
    async saveDeleteAddOn() {
      let payloadSheet = {
        sub_id: this.user.sub_id,
        sheetName: 'Add Ons',
        driveId: this.$route.params.driveId,
        excelData: [this.payloadDelete.rowNumber],
      }

      if (this.selectedTableData.length !== 0) {
        payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Add Ons',
          driveId: this.$route.params.driveId,
          excelData: this.selectedTableData.map(el => el.rowNumber),
        }
      }

      this.loadingDelete = true

      const res = await this.$store.dispatch('eOrdering/deleteSheet', payloadSheet)

      if (res.status) {
        this.selectedTableData = []
        this.loadingDelete = false
        this.openDeleteAddOnSheet = false
        const payload = {
          id: this.$route.params.id,
          driveId: this.$route.params.driveId,
          sub_id: this.user.sub_id,
        }
        this.loading = true
        await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
        await this.$store.dispatch('eOrdering/getSheetData', {
          sub_id: this.user.sub_id,
          excelId: this.$route.params.id,
        })
        this.loading = false
      }
      this.selectedTableData = []
      this.loadingDelete = false
      this.openDeleteAddOnSheet = false
    },
    closeDeleteAddOnDialog() {
      this.openDeleteAddOnSheet = false
    },

    //! Edit Coupon

    validateCoupon(data) {
      // //console.log(data, `INI DATA`)
      let flag = true
      if (!data['Discount Percentage']) {
        this.formValidationMessage = 'Discount Percentage is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data['Expired Date']) {
        this.formValidationMessage = 'Expired Date is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data['Start Date']) {
        this.formValidationMessage = 'Start Date is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data.Status) {
        this.formValidationMessage = 'Status is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data['Minimum Buy']) {
        this.formValidationMessage = 'Minimum Buy is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data['Max Discount']) {
        this.formValidationMessage = 'Max Discount is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data.Code) {
        this.formValidationMessage = 'Code is Required'
        this.showSnackbarWarning = true
        flag = false
      }

      return flag
    },
    async saveEditCoupon() {
      if (this.validateCoupon(this.payloadEdit)) {
        if (this.payloadEdit.discountPercentage) {
          this.payloadEdit['Discount Percentage'] = `${this.payloadEdit.discountPercentage}%`
          delete this.payloadEdit.discountPercentage
        }

        const payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Coupons',
          driveId: this.$route.params.driveId,
          excelData: {},
        }

        payloadSheet.excelData[this.payloadEdit.rowNumber] = JSON.parse(JSON.stringify(this.payloadEdit))
        delete payloadSheet.excelData[this.payloadEdit.rowNumber].id
        delete payloadSheet.excelData[this.payloadEdit.rowNumber].rowNumber

        this.loadingEdit = true

        const res = await this.$store.dispatch('eOrdering/editSheet', payloadSheet)

        if (res.status) {
          this.loadingEdit = false
          this.openEditCouponSheet = false
          this.payloadEdit = {}
          const payload = {
            id: this.$route.params.id,
            driveId: this.$route.params.driveId,
            sub_id: this.user.sub_id,
          }
          this.loading = true
          await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
          await this.$store.dispatch('eOrdering/getSheetData', {
            sub_id: this.user.sub_id,
            excelId: this.$route.params.id,
          })
          this.loading = false
        }
        this.loadingEdit = false
        this.openEditCouponSheet = false
      }
    },
    closeEditCouponDialog() {
      this.openEditCouponSheet = false
    },

    //! Add Coupon
    async saveAddCoupon() {
      if (this.validateCoupon(this.payloadAdd)) {
        if (this.payloadAdd.discountPercentage) {
          this.payloadAdd['Discount Percentage'] = `${this.payloadAdd.discountPercentage}%`
          delete this.payloadAdd.discountPercentage
        }

        const payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Coupons',
          driveId: this.$route.params.driveId,
          excelData: [JSON.parse(JSON.stringify(this.payloadAdd))],
        }

        this.loadingAdd = true
        const res = await this.$store.dispatch('eOrdering/addSheet', payloadSheet)

        if (res.status) {
          this.loadingAdd = false
          this.openAddCouponSheet = false
          this.payloadAdd = {}
          const payload = {
            id: this.$route.params.id,
            driveId: this.$route.params.driveId,
            sub_id: this.user.sub_id,
          }
          this.loading = true
          await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
          await this.$store.dispatch('eOrdering/getSheetData', {
            sub_id: this.user.sub_id,
            excelId: this.$route.params.id,
          })
          this.loading = false
        }
        this.loadingAdd = false
        this.closeAddCouponDialog()
      }
    },
    closeAddCouponDialog() {
      this.openAddCouponSheet = false
    },

    //! Delete Coupon
    async saveDeleteCoupon() {
      let payloadSheet = {
        sub_id: this.user.sub_id,
        sheetName: 'Coupons',
        driveId: this.$route.params.driveId,
        excelData: [this.payloadDelete.rowNumber],
      }

      if (this.selectedTableData.length !== 0) {
        payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Coupons',
          driveId: this.$route.params.driveId,
          excelData: this.selectedTableData.map(el => el.rowNumber),
        }
      }

      this.loadingDelete = true

      const res = await this.$store.dispatch('eOrdering/deleteSheet', payloadSheet)

      if (res.status) {
        this.selectedTableData = []
        this.loadingDelete = false
        this.openDeleteCouponSheet = false
        const payload = {
          id: this.$route.params.id,
          driveId: this.$route.params.driveId,
          sub_id: this.user.sub_id,
        }
        this.loading = true
        await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
        await this.$store.dispatch('eOrdering/getSheetData', {
          sub_id: this.user.sub_id,
          excelId: this.$route.params.id,
        })
        this.loading = false
      }
      this.selectedTableData = []
      this.loadingDelete = false
      this.openDeleteCouponSheet = false
    },
    closeDeleteCouponDialog() {
      this.openDeleteCouponSheet = false
    },

    validateBanner(data) {
      let flag = true
      if (!data.Image) {
        this.formValidationMessage = 'Banner image is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data['Expired Date']) {
        this.formValidationMessage = 'Banner Expired Date is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data['Start Date']) {
        this.formValidationMessage = 'Banner Start Date is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data.Status) {
        this.formValidationMessage = 'Banner Status is Required'
        this.showSnackbarWarning = true
        flag = false
      } else if (!data.Name) {
        this.formValidationMessage = 'Banner Name is Required'
        this.showSnackbarWarning = true
        flag = false
      }

      return flag
    },

    //! Edit Banner

    async saveEditBanner() {
      this.payloadEdit.Image = this.$refs['uppy-data-banner']?.uppy?.getFiles()[0]
        ? this.$refs['uppy-data-banner']?.uppy?.getFiles()[0].uploadURL
        : this.payloadEdit.Image

      if (this.validateBanner(this.payloadEdit)) {
        const payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Banners',
          driveId: this.$route.params.driveId,
          excelData: {},
        }

        payloadSheet.excelData[this.payloadEdit.rowNumber] = this.payloadEdit
        delete payloadSheet.excelData[this.payloadEdit.rowNumber].id
        delete payloadSheet.excelData[this.payloadEdit.rowNumber].rowNumber

        this.loadingEdit = true

        const res = await this.$store.dispatch('eOrdering/editSheet', payloadSheet)

        if (res.status) {
          this.loadingEdit = false
          this.openEditBannerSheet = false
          this.payloadEdit = {}
          const payload = {
            id: this.$route.params.id,
            driveId: this.$route.params.driveId,
            sub_id: this.user.sub_id,
          }
          this.loading = true
          await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
          await this.$store.dispatch('eOrdering/getSheetData', {
            sub_id: this.user.sub_id,
            excelId: this.$route.params.id,
          })
          this.loading = false
        }
        this.loadingEdit = false
        this.openEditBannerSheet = false
      }
    },
    closeEditBannerDialog() {
      this.openEditBannerSheet = false
    },

    //! Add Banner
    async saveAddBanner() {
      this.payloadAdd.Image = this.$refs['uppy-data-banner']?.uppy?.getFiles()[0]
        ? this.$refs['uppy-data-banner']?.uppy?.getFiles()[0].uploadURL
        : null
      if (this.validateBanner(this.payloadAdd)) {
        const payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Banners',
          driveId: this.$route.params.driveId,
          excelData: [this.payloadAdd],
        }

        this.loadingAdd = true

        const res = await this.$store.dispatch('eOrdering/addSheet', payloadSheet)

        if (res.status) {
          this.loadingAdd = false
          this.openAddBannerSheet = false
          this.payloadAdd = {}
          const payload = {
            id: this.$route.params.id,
            driveId: this.$route.params.driveId,
            sub_id: this.user.sub_id,
          }
          this.loading = true
          await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
          await this.$store.dispatch('eOrdering/getSheetData', {
            sub_id: this.user.sub_id,
            excelId: this.$route.params.id,
          })
          this.loading = false
        }
        this.loadingAdd = false
        this.closeAddBannerDialog()
      }
    },
    closeAddBannerDialog() {
      this.openAddBannerSheet = false
      this.propsUppyUploader = null
      this.dataIndex = Math.round(Math.random() * 100000)
    },

    //! Delete Banner
    async saveDeleteBanner() {
      let payloadSheet = {
        sub_id: this.user.sub_id,
        sheetName: 'Banners',
        driveId: this.$route.params.driveId,
        excelData: [this.payloadDelete.rowNumber],
      }

      if (this.selectedTableData.length !== 0) {
        payloadSheet = {
          sub_id: this.user.sub_id,
          sheetName: 'Banners',
          driveId: this.$route.params.driveId,
          excelData: this.selectedTableData.map(el => el.rowNumber),
        }
      }

      this.loadingDelete = true

      const res = await this.$store.dispatch('eOrdering/deleteSheet', payloadSheet)

      if (res.status) {
        this.selectedTableData = []
        this.loadingDelete = false
        this.openDeleteBannerSheet = false
        const payload = {
          id: this.$route.params.id,
          driveId: this.$route.params.driveId,
          sub_id: this.user.sub_id,
        }
        this.loading = true
        await this.$store.dispatch('eOrdering/syncExcelProducts', payload)
        await this.$store.dispatch('eOrdering/getSheetData', {
          sub_id: this.user.sub_id,
          excelId: this.$route.params.id,
        })
        this.loading = false
      }
      this.selectedTableData = []
      this.loadingDelete = false
      this.openDeleteBannerSheet = false
    },
    closeDeleteBannerDialog() {
      this.openDeleteBannerSheet = false
    },
  },
}
</script>

<style lang="scss" scoped>
.height-dialog {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.card-product {
  width: 100%;
  min-height: 400px;
  border: 1px solid #c9c9c9;
  border-radius: 10px;

  div {
    img {
      object-fit: cover;
      width: 100%;
      height: 200px;
    }
  }
}
.size-button-action {
  font-size: 14px;
}

.border-card {
  border: 0.5px solid #c6c6c6;
}

.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}

.gamification-tree {
  top: 10%;
  right: 0;
}

.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}

.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

.hero-landing {
  margin-left: 4%;
  margin-right: 4%;
  justify-content: center;
}

.hero-subtitle {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }

    .gamification-tree {
      right: initial;
      left: 0;
    }

    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}

.mobile-header {
  font-size: 24px;
}

@media only screen and (max-width: 578px) {
  .mobile-header {
    font-size: 18px;
  }
}

.bottom-action {
  display: none;
}

@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }

  .bottom-action {
    display: inherit;
  }

  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
  .size-button-action {
    font-size: 10px;
  }
}
@media (min-width: 1060px) and (max-width: 1903px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>
